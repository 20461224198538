import axios from '../../config';
export const SET_SPLITPRICE_DETAILS = "SET_SPLITPRICE_DETAILS";
export const CLEAR_SPLITPRICE_DETAILS = "CLEAR_SPLITPRICE_DETAILS";

export const setSplitPriceDetails = (payload: any) => ({
    type: SET_SPLITPRICE_DETAILS,
    payload
})
export const fetchSplitPriceDetails = (payload:any) => async (dispatch: any) => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    
    try {

        const bodyFormData = new FormData();
        const token = localStorage.getItem("token");
        const api_url = process.env.REACT_APP_API_URL;
        const response = await axios({
            method: "get",
            url: `${base_url}/api/get_price_split_details?total_price_amount=${payload?.finalTotalPrice}&offer_id=${payload?.offerId}`,
            data: bodyFormData,
            headers: {
                 "Accept": "application/json", 
                 "Authorization": "Bearer " + token,
                 'Access-Control-Allow-Methods': 'GET, POST' },
        })
        if (response) {
            dispatch(setSplitPriceDetails({...response.data?.data, itemsCount: payload?.itemsCount}))
        }
    } catch (error) {
        
    }
};