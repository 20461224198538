import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './downloadApp.css';
import ThankAppDownload from '../thankAppDownload';
import FailedAppDownload from '../failedAppDownload';
import { appstore,googleplay,appdownload } from '../../assets/img'

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;

function DownloadApp() {

  const [mobileValue, setMobileValue] = useState("")
  const [result, setResult] = useState({})
  const [thanksAppDownloadPopup, setThanksAppDownloadPopup] = useState(false)
  const [failedAppDownloadPopup, setFailedAppDownloadPopup] = useState(false)
  const [error, genError] = useState("");
  const [appLinks, setappLinks] = useState<any>('');

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/[^0-9+]/gi, '');
    if (result.length > 14) {
      genError('Number should not be greater than 14 digits!');
      return;
    }else{
      genError('');
    }
    setMobileValue(result);
  }

  const handleSubmit = (event: any) => {
    const api_url = process.env.REACT_APP_API_URL;
    const headers = { 'Accept': 'application/json'}

    axios.post(`${base_url}/api/download_app`, { mobile: mobileValue }, {
      headers: headers

    }).then(e => {
      setResult({ result: e.data })

      //alert(e.data.Response.response_message)

      if (e.data.Response.response_code == "1") {
        setThanksAppDownloadPopup(true)
      }
      else {

        setFailedAppDownloadPopup(true)
        /*   setGenError(e.data.Response.response_message);
           event.preventDefault()
           return false*/
      }
    })
    event.preventDefault()
  }

  useEffect(() => {
    const api_url = process.env.REACT_APP_API_URL;
    if (appLinks === '') {
    axios({
      method: "get",
      url: `${base_url}/api/settings`,
      headers: {
        "Accept": "application/json",
        'Access-Control-Allow-Methods': 'GET, POST',
        
      },
    }).then((response) => {
      if (response.status !== 401) {
        setappLinks(response.data.data.WebmasterSettings)
      }
    }).catch(function (error) {
      
    })
  }
  }, [appLinks]);


  return (
    <div className="section-app app-download-blk">

      <div className="container">
        <div className="row">
          <div className="app-content col-lg-6 aos-init" data-aos="zoom-in-up">
            <form id="downloadapp" role="form" method="POST" onSubmit={handleSubmit}  >
              <h2>Download the app</h2>
              <p>With our award-winning app, your laundry and dry cleaning needs are just a tap away</p>
              <div className="input-group my-32">
                <input id="download_link_input" value={mobileValue} type="text" className="form-control" aria-label="" max={14} placeholder={`Eg:- ${appLinks?.mobile_notification_prefix != undefined ? appLinks?.mobile_notification_prefix : ''}07816545718`} onChange={handleChange} />
                <span className="input-group-addon custom-style-btn p-0"><button id="download_link_btn" className="btn"><i className="fa fa-paper-plane"></i></button></span>
              </div>
              <h6 className='text-danger mb-8'>{error}</h6>
              <ul>
                <li><a target={appLinks.app_store_link == ""? '' : "_blank"} href={appLinks.app_store_link == ""? 'javascript:;' : appLinks.app_store_link} className="hvr-float-shadow"><img src={appstore} className="img img-fluid" alt="appstore" /></a></li>
                <li><a target={appLinks.play_store_link == ""? '' : "_blank"} href={appLinks.play_store_link == ""? 'javascript:;' : appLinks.play_store_link} className="hvr-float-shadow"><img src={googleplay} className="img img-fluid" alt="appstore" /></a></li>
              </ul>
            </form>
          </div>
          <div className="app-img col-lg-6">            
              <img src={appdownload} className="img-fluid aos-init" alt="" data-aos="zoom-in-up" />
          </div>
        </div>

      </div>



      {thanksAppDownloadPopup && <ThankAppDownload close={() => setThanksAppDownloadPopup(false)} />}
      {failedAppDownloadPopup && <FailedAppDownload close={() => setFailedAppDownloadPopup(false)} />}

    </div>
  )
}

export default DownloadApp;


