import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./../footer/footer.css";
import { NavLink } from "react-router-dom";
import { appstore, googleplay } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload,
});

function Footer() {
  const navigate = useNavigate();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const base_url = process.env.REACT_APP_BACKEND_URL;
  const api_url = process.env.REACT_APP_API_URL;

  const [settings, setSettings] = useState<any>({});

  const appStore =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.app_store_link;
  const playStore =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.play_store_link;
  const facebook =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1;
  const twitter =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2;
  const instagram =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6;
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (Object.keys(settings).length === 0) {
      axios({
        method: "get",
        url: `${base_url}/api/settings`,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Methods": "GET, POST",
          
        },
      })
        .then((response) => {
          if (response.status != 401) {
            setSettings(response.data.data);
            dispatch(setSettingsAll(response.data.data));
          }
        })
        .catch(function (error) {});
    }
  });

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row footer-one">
            <div className="col-md-12">
              <div className="home-links">
                <ul>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return { color: isActive ? "#efc10b" : "" };
                      }}
                      className="nav-bar-link"
                      to={"/aboutUs"}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => {
                        return { color: isActive ? "#efc10b" : "" };
                      }}
                      className="nav-bar-link"
                      to={"/areaCovered"}
                    >
                      Area Covered
                    </NavLink>
                  </li>
                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/faq'}>FAQ</NavLink></li>
                  {/* <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/reviews'}>Reviews</NavLink></li> */}
                  {/* <li><a target="_blank" href={blogUrl}>Blog</a></li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="row footer-one">
            {/* <div
              className="col-md-3 col-sm-6">
              <div
                className="footer-two">
                <div
                  className="footerlist-two">
                  <h4>We are here for you..</h4>
                  <ul>
                    <li><span>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t1_en}</span></li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-md-4 col-sm-6">
              <div className="footer-three">
                <div className="footerlist-two">
                  <h4>Need Help?</h4>
                  <ul>
                    <li>
                      <span>Phone :</span>
                      &nbsp;
                      <span>
                        {Object.keys(settings).length > 0 &&
                            settings.WebsiteSettings.contact_t5}
                        {/* <a
                          href={`tel:${
                            Object.keys(settings).length > 0 &&
                            settings.WebsiteSettings.contact_t5
                          }`}
                        >
                          {Object.keys(settings).length > 0 &&
                            settings.WebsiteSettings.contact_t5}
                        </a> */}
                      </span>
                    </li>
                    <li>
                      <span>Email :</span>
                      &nbsp;
                      <span>
                        <a
                          href={`mailto:${
                            Object.keys(settings).length > 0 &&
                            settings.WebsiteSettings.contact_t6
                          }`}
                        >
                          {Object.keys(settings).length > 0 &&
                            settings.WebsiteSettings.contact_t6}
                        </a>
                      </span>
                    </li>
                    {/* <li><i
                      className="fa fa-envelope" aria-hidden="true"></i><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li>
                    <li><i
                      className="fa fa-envelope" aria-hidden="true"></i><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="footer-three">
                <div className="footerlist-two">
                  <h4>Our Policy</h4>
                  <ul>
                    <li>
                      <NavLink to={"/termsAndConditions"}>
                        <i className="fas fa-angle-right me-1"></i>Conditions of
                        use
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/privacyPolicy"}>
                        <i className="fas fa-angle-right me-1"></i>Privacy
                        Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/cookiePolicy"}>
                        <i className="fas fa-angle-right me-1"></i>Cookie Policy
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="footer-three">
                <div className="footerlist-two">
                  <h4>Quick Connect</h4>
                  <div className="app-icons">
                    <ul className="list-inline">
                      <li>
                        <a
                          target={appStore == "" ? "" : "_blank"}
                          href={appStore == "" ? "javascript:;" : appStore}
                          className="hvr-float-shadow imggbg"
                        >
                          <img
                            src={appstore}
                            className="img img-fluid"
                            alt="appstore"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          target={playStore == "" ? "" : "_blank"}
                          href={playStore == "" ? "javascript:;" : playStore}
                          className="hvr-float-shadow imggbg2"
                        >
                          <img
                            src={googleplay}
                            className="img img-fluid"
                            alt="appstore"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <p className="footer-last">
                ©{" "}
                {Object.keys(settings).length > 0 &&
                  settings?.WebsiteSettings?.site_title_en}{" "}
                {year}. All rights reserved. | Powered by <a target='_blank' href='https://bestatservices.com/' >www.bestatservices.com</a>
                {/* © <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_title_en}</a> 2023. All rights reserved. |
                Designed &amp; Developed By : <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url} target="_blank">{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}</a> */}
              </p>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="social-links text-end">
                <div className="footer-social md-mt">
                  <ul className="list-inline">
                    <li style={{ cursor: "pointer" }}>
                      {facebook == "" ? (
                        <>
                          <a className="foo-icon">
                            <i
                              className="fab fa-facebook-f"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className="foo-icon"
                            target={"_blank"}
                            href={facebook}
                          >
                            <i
                              className="fab fa-facebook-f"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      )}
                    </li>

                    <li style={{ cursor: "pointer" }}>
                      {instagram == "" ? (
                        <>
                          <a className="foo-icon">
                            <i
                              className="fab fa-instagram"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className="foo-icon"
                            target="_blank"
                            href={instagram}
                          >
                            <i
                              className="fab fa-instagram"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      )}
                    </li>

                    <li style={{ cursor: "pointer" }}>
                      {twitter == "" ? (
                        <>
                          <a className="foo-icon">
                            <i
                              className="fab fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className="foo-icon"
                            target="_blank"
                            href={twitter}
                          >
                            <i
                              className="fab fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      )}
                    </li>

                    <li style={{ cursor: "pointer" }}>
                      {settings?.WebsiteSettings?.social_link10 == "" ? (
                        <>
                          <a
                            className="foo-icon"
                          >
                            <i
                              className="fab fa-whatsapp"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className="foo-icon"
                            target="_blank"
                            href={settings?.WebsiteSettings?.social_link10}
                          >
                            <i
                              className="fab fa-whatsapp"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
