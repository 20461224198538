import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./../faq/faqQuestions.css";
import { fetchFaqData } from "../../redux/Actions/policyActions";

function Corporate() {
  const settingsData: any = useSelector<any>((state) => state?.settings);

  var metaTitle = "";
  var metaDescription = "";

  useEffect(() => {
    metaTitle = "Corporate Laundry Services | " + settingsData?.WebsiteSettings?.site_title_en;
    metaDescription = settingsData?.WebsiteSettings?.site_title_en + " offers comprehensive corporate laundry services tailored to your business needs. Discover our corporate solutions and book your services online today!";
    document.title = metaTitle;
    document.getElementById("title")?.setAttribute("content", metaTitle);
    document.getElementById("description")?.setAttribute("content", metaDescription);
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Corporate</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Corporate Customers</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 d-none">
              <ol className="breadcrumb">
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="active">FAQs</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="faq-content d-none">
                <h2>FAQ'S</h2>
                <p>Quickly find out it we've already addressed your qurey</p>
              </div>
              <section className="section-corporate">
                <div className="container">
                  <div className="row">
                    <section className="corporate-addition">
                      <div className="container">
                        <div className="row">
                          <div className="group-corporate ">
                            <p>We know how busy businesses can be, which is why we make our corporate laundry services easy as can be.
                              We’ll not only pick up your laundry at a time and place that suits you, but we’ll also drop it off at a convenient time for you too.
                              Not to mention our quick turn around times and great prices.
                              Our customers come from an extensive number of sectors, which has given us immense experience in all types of corporate laundry needs.
                            </p>
                            <p>We’ve built long lasting relationships with all types of businesses within London and its surrounding areas.
                              Our services cater to all your laundry, ironing and dry cleaning needs and we can also take care of shoe repairs, upholstery cleaning and much more.
                              Our highly efficient team will ensure you face no difficulty and you can rest assured that your laundry will be serviced with state of the art technology.</p>
                            <p>We provide a responsive, discrete and reliable service tailored to your company’s specific needs.
                              To discuss your requirements call <a href="tel:07932607424">07932607424</a> or email <a href="mailto:info@laundrybase.co.uk">info@laundrybase.co.uk.</a></p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Corporate;
