import React, { useEffect, useState } from "react";
import CancelPopup from "../../cancelPopup";
import TrackOrderPopup from "../../trackOrderPopup";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchMyOrders } from "../../../redux/Actions/myOrderAction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { format, parse } from "date-fns";
import {
  pending,
  trackorder,
  vieworder,
  myorderimg,
} from "../../../assets/img";

function MyOrders() {
  const notify = (message: string) => toast(message);

  const [cancelPopup, setCancelPopup] = useState(false);
  const [trackOrderPopup, setTrackOrderPoup] = useState(false);
  const [myOrderList, setMyOrderList] = useState<any>([]);
  const [orderId, setOrderId] = useState("");
  const [pagination, setPagination] = useState<any>({
    itemsPerPage: 10,
    currentPage: 1,
    totalCount: 0,
    pages: [],
  });
  const [next, setNext] = useState<any>([]);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(10);

  const dispatch = useDispatch<any>();
  const myOrders: any = useSelector<any>((state) => state?.myOrderList?.data);
  const settings: any = useSelector<any>((state) => state.settings);
  const navigate = useNavigate();

  // const data = [];
  // const totalPages = Math.ceil(data.length / itemsPerPage);
  const convertToPages = (data: number) => {
    const totalPages = Math.ceil(data / pagination.itemsPerPage);
    let pagesArr = [];
    for (let i = 0; i < totalPages; i++) {
      pagesArr.push(i + 1);
    }
    return pagesArr;
  };

  useEffect(() => {
    if (myOrders) {
      const { orders_list } = myOrders;
      const totalCount = orders_list?.orders_count;
      setPagination({
        ...pagination,
        totalCount,
        pages: convertToPages(totalCount),
      });
      setMyOrderList(orders_list);
    }
  }, [myOrders]);

  useEffect(() => {
    // fetchData();
    // dispatch(fetchMyOrders(limit,page,sort,order_status))
    dispatch(
      fetchMyOrders(pagination.itemsPerPage, pagination.currentPage, 0, "all")
    );
    // dispatch(
    //   fetchMyOrders(10 ,3, 0, "all")
    // );
  }, []);

  const handleViewOrder = (order_id: string) => {
    navigate(`/orderView/${order_id}`);
  };

  function setTrackOrder(order_id: string) {
    setOrderId(order_id);
    setTrackOrderPoup(!trackOrderPopup);
  }

  const handlePagination = (page: number, type?: string) => {
    if (page) {
      setPagination({
        ...pagination,
        currentPage: page,
      });
      dispatch(fetchMyOrders(pagination.itemsPerPage, page, 0, "all"));
    }
    switch (type) {
      case "first":
        setPagination({
          ...pagination,
          currentPage: 1,
        });
        dispatch(fetchMyOrders(pagination.itemsPerPage, 1, 0, "all"));
        break;
      case "last":
        setPagination({
          ...pagination,
          currentPage: pagination.pages[pagination.pages.length - 1],
        });
        dispatch(
          fetchMyOrders(
            pagination.itemsPerPage,
            pagination.pages[pagination.pages.length - 1],
            0,
            "all"
          )
        );
        break;
      case "prev":
        setPagination({
          ...pagination,
          currentPage: pagination.currentPage - 1,
        });
        dispatch(
          fetchMyOrders(
            pagination.itemsPerPage,
            pagination.currentPage - 1,
            0,
            "all"
          )
        );
        break;
      case "next":
        setPagination({
          ...pagination,
          currentPage: pagination.currentPage + 1,
        });
        dispatch(
          fetchMyOrders(
            pagination.itemsPerPage,
            pagination.currentPage + 1,
            0,
            "all"
          )
        );
        break;
      default:
        break;
    }
  };
  const handleNext = () => {
    setNext(pagination.currentPage + 1);
  };

  const base_url = process.env.REACT_APP_BACKEND_URL;

  function setCancelOrder(order_id: string) {
    setOrderId(order_id);
    setCancelPopup(!cancelPopup);
  }

  // function isWindows() {
  //   return navigator.userAgent.includes("Win");
  // }

  // function formatWindowsDate(date: any) {
  //   return format(new Date(date), "dd-MM-yyyy");
  // }

  // function formatMacDate(date: any) {
  //   return format(parse(date, "yyyy-MM-dd", new Date()), "dd-MM-yyyy");
  // }

  const datesFormat = (date: any) => {
    let selectedDate = new Date(date);
    const yyyy = selectedDate.getFullYear();
    let mm: any = selectedDate.getMonth() + 1; // Months start at 0!
    let dd: any = selectedDate.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "-" + mm + "-" + yyyy;
    return formattedToday;
  };

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>My Orders</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> My Orders </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-myorders">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row myorder-row">
                <div className="col-md-5 col-xs-4">
                  <h1 className="myorder-title">My Orders</h1>
                </div>
                <div className="col-md-7 col-xs-8">
                  <div className="myorder-backhome float-end d-none d-md-block">
                    <span>
                      <a href="/myProfile">
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                        Back to my profile
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              {myOrderList &&
                myOrderList?.orders?.map((item: any) => (
                  <div className="my-orders">
                    <div className="myorder-list">
                      <div className="myorder-header">
                        <span className="order-idtag">
                          Order ID - {item.order_id}
                        </span>
                        <div className="order-price">
                          <div className="custom-order-price">
                            <span className="ctm-offer">Price: </span>{" "}
                            <span className="mob-offers">
                              {" "}
                              {settings?.WebmasterSettings?.currency}
                              {item.total_amout}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="orders">
                      <div className="customorder">
                        <div className="custom-order">
                          <span className="lft-order">Order Date :</span>
                          <span className="rht-details">
                            {/* {format(new Date(item.order_date), "dd-MM-yyyy")} */}
                            {/* {format(parse(item.order_date, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')} */}
                            {/* {isWindows()
                              ? formatWindowsDate(item.order_date)
                              : formatMacDate(item.order_date)} */}
                            {datesFormat(item.order_date)}
                          </span>
                        </div>
                        <div className="custom-order">
                          <span className="lft-order">Service :</span>
                          <span className="rht-details">Drycleaning</span>
                        </div>
                        <div className="custom-order">
                          <span className="lft-order">
                            Pickup &amp; Delivery :
                          </span>
                          <span className="rht-details">
                            {" "}
                            {/* {format(new Date(item.pickup_date), "dd-MM-yyyy")}  */}
                            {/* {format(parse(item.pickup_date, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')} */}
                            {/* {isWindows()
                              ? formatWindowsDate(item.pickup_date)
                              : formatMacDate(item.pickup_date)} */}
                            {datesFormat(item.pickup_date)}(
                            {item.pickup_time_slot}) &amp;{" "}
                            {/* {format(new Date(item.delivery_date), "dd-MM-yyyy")} */}
                            {/* {format(parse(item.delivery_date, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')} */}
                            {/* {isWindows()
                              ? formatWindowsDate(item.delivery_date)
                              : formatMacDate(item.delivery_date)} */}
                            {datesFormat(item.delivery_date)} (
                            {item.delivery_time_slot}){" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="order-status">
                      <ul>
                        <li className="order-pending">
                          <img src={pending} className="img-fluid" alt="" />
                          {item.cancelled === "1"
                            ? "Cancelled"
                            : item.order_status.current_status
                                .split("_")
                                .map(
                                  (word:any) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                        </li>
                        {item.cancelled == "0" &&
                          item.order_status.current_status.toLowerCase() ==
                            "pending" && (
                            <li
                              data-order-id="83"
                              className="text-danger cancel-order"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              onClick={() => setCancelOrder(item.order_id)}
                            >
                              <a
                                className="text-danger"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <i className="fa fa-times"></i> Cancel{" "}
                              </a>
                            </li>
                          )}
                        {item.cancelled == "0" && (
                          <li
                            className="track-order"
                            onClick={(e) => setTrackOrder(item.order_id)}
                          >
                            <a
                              href="#"
                              className="order-track"
                              data-bs-toggle="modal"
                              data-bs-target="#8QT3BN"
                            >
                              <img
                                src={trackorder}
                                className="img-fluid"
                                alt=""
                              />
                              Track Order
                            </a>
                          </li>
                        )}
                        <li
                          className="order-approved"
                          onClick={() => {
                            handleViewOrder(item.order_id);
                          }}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <img src={vieworder} className="img-fluid" alt="" />{" "}
                            View Order
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {myOrderList.length !== 0 && (
            <div className="row">
              <div className="col-md-12 pagination_myorder">
                <nav className="myorder-pagination">
                  <ul className="pagination">
                    <li
                      className="page-item "
                      onClick={() => {
                        if (pagination.currentPage > 1) {
                          handlePagination(0, "first");
                        }
                      }}
                    >
                      <a
                        href="#"
                        className="page-link"
                        style={{
                          cursor:
                            pagination.currentPage > 1
                              ? "pointer"
                              : "not-allowed",
                        }}
                      >
                        First
                      </a>
                    </li>

                    <li
                      className={pagination.currentPage === 1 ? "disabled" : ""}
                      onClick={() => {
                        if (pagination.currentPage > 1) {
                          handlePagination(0, "prev");
                        }
                      }}
                      style={{
                        cursor:
                          pagination.currentPage > 1
                            ? "pointer"
                            : "not-allowed",
                      }}
                    >
                      <span>«</span>
                    </li>
                    {pagination.pages &&
                      pagination.pages.map((page: number, idx: number) => (
                        <li
                          key={idx}
                          onClick={() => handlePagination(page)}
                          className={`${
                            page === pagination.currentPage ? "active" : ""
                          }`}
                        >
                          <span style={{ cursor: "pointer" }}>{page} </span>
                        </li>
                      ))}
                    <li
                      onClick={() => {
                        if (pagination.currentPage < pagination.pages.length) {
                          handlePagination(0, "next");
                        }
                      }}
                      style={{
                        cursor:
                          pagination.currentPage < pagination.pages.length
                            ? "pointer"
                            : "not-allowed",
                      }}
                    >
                      <span>»</span>
                    </li>
                    <li
                      className="page-item "
                      onClick={() => {
                        if (pagination.currentPage < pagination.pages.length) {
                          handlePagination(0, "last");
                        }
                      }}
                    >
                      <a
                        href="#"
                        className="page-link"
                        style={{
                          cursor:
                            pagination.currentPage < pagination.pages.length
                              ? "pointer"
                              : "not-allowed",
                        }}
                      >
                        Last
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
          {myOrderList.length === 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="res-not-found text-center">
                  <div className="res-not-icon">
                    <i className="fas fa-search"></i>
                  </div>
                  <h1>No Order Found</h1>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {cancelPopup && (
        <CancelPopup orderId={orderId} close={() => setCancelPopup(false)} />
      )}
      {trackOrderPopup && (
        <TrackOrderPopup
          close={() => setTrackOrderPoup(false)}
          orderId={orderId}
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default MyOrders;
