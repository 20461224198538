import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate,useLocation } from 'react-router-dom';
import { notification1 } from '../../assets/img'
import '../notification/notification-box.css';

function Notification(props:any) {
  const navigate = useNavigate();

  const location = useLocation();
  let url = location.pathname
  let splitURL = url. toString(). split("/")
  const navigateToOrderView = (orderId:any)=>{
    if (orderId !== "" && splitURL[1] != "orderView") {
    navigate(`orderView/${orderId}`)
    }
  }

  useEffect(() => {
  }, [props])

  return (

    <div className='notification notification-dropdown'>

          <div className="notification-header">
            <p><a href="#" onClick={(e) => { e.preventDefault(); navigate('/notifications') }} target="_blank" className="header-notification">Notification</a></p>
            {/* <i className="fa fa-cog float-end" aria-hidden="true"></i> */}
          </div>
          <div className="notification-items">
            {(Array.isArray(props.notification) ? props.notification : []).map((notification: any, index: number) =>
              <li key={index} role="presentation" className='presentations'>
                <div className="alert-dismissible nf-dismissible">
                  <div className="notification-content">
                    <img src={notification1} className="img-fluid" alt="notification" />
                    <div className="nf-content" onClick={() => navigateToOrderView(notification.order_id)}>
                      <p>{notification.title}</p>
                      <span>{notification.body}</span>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </div>
          <p className="notify-seeall">
            <a href="#" onClick={(e) => { e.preventDefault(); navigate('/notifications') }} className='all-nitification' target="_blank">See All Notification</a>
          </p>

    </div>
  )
}

export default Notification;