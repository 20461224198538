import React from 'react'
import { easytoimg } from '../../assets/img'
import "./laundryService.css";

function LaundryService() {
  return (
    <div className="easelaundry-wrapper">
        
<div className="container">
<div className="row">
<div className="col-lg-6">
<div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
<img src={easytoimg} className="img-fluid aos-init"  alt="" data-aos="zoom-in-up" />
</div>
</div>
<div className="col-lg-6">
<div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
<div className="section-title text-start">
<h1 className="text-start">Laundry Made Easy</h1>
</div>
<div className="">
<p> Laundry Base is a leading online laundry and dry cleaning service, offering unparalleled levels of quality cleaning. Our five-star laundry service replaces the need for using your local launderette or dry cleaner and offers a quick and convenient collection and delivery service direct from your home. </p>
<p> We also make sure we only ever use the best detergents and other cleaning materials. It is this combination of advanced machinery, and top quality consumables that makes us the best laundry service in the area. </p>
<p> If you have ever taken your garments to a cheap cleaning service, and they have come back without stains being removed, smell, or are not as bright as they could be, this is because the cleaner used cheap detergent and old machinery. We believe that you get what you pay for. We use only the best, so that your clothing is returned to you completely clean and in perfect condition. </p>
</div>
</div>
</div>
</div>

</div>

    </div>
  )
}

export default LaundryService;