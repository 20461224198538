import React from 'react'
import {aboutimg,abouticon1,abouticon2,abouticon3,abouticon4} from '../../assets/img'

function AboutBestAtLaundry() {
  
  return (
    <div>
      <section className="aboutsec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 d-none d-md-block">
              <div className="about-img-left aos-init" data-aos="zoom-in-up">
                <img src={aboutimg} className="img-fluid aos-init" alt="" data-aos="zoom-in-up" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content-right aos-init" data-aos="zoom-in-up">
                  <h3 className="text-start">About LAUNDRY BASE</h3>
                  <h2>Professional dry cleaning & laundry services in London.</h2>
                  <div className="section-line">
                    <span className='first-line'></span>
                  </div>
                  <div className="section-line small-line">
                    <span className='second-line'></span>
                  </div>

                <p>We at Laundry Base have our own facilities throughout London and have full control 
over the service that we provide. We are, therefore, more efficient, more control over 
what we do and fully responsible for the service we provide.</p>
                <div className="about-icon">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon1} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Cost Effective</h5>
                          <p>Laundry Base provides the laundry services with affordable cost to all when compared to others on the market.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon2} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Ease to Use</h5>
                          <p>Simple and ease of using our services.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon3} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Fast Delivery</h5>
                          <p>Our team will collect and deliver your cloth items quickly free of charge.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon4} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Quality of Service</h5>
                          <p>Our team will perfectly wash, dry clean, iron, fold, pack and return to you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></section>
    </div>
  )
}

export default AboutBestAtLaundry