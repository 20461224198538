import React, { useEffect, useState } from "react";
import "./../rushmoor/rushMoor.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { procstep1, procstep2, procstep3, procstep4 } from "../../assets/img";
import { setValue } from "../../utility";
import { NavLink } from "react-router-dom";
import { fetchShopsBasedPincode } from "../../redux/Actions/shopHomeAction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import QuickOrderPopup from "../quickOrderPopup";

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Post code is required")
    .matches(UKPostalCode, "Enter a valid Post code")
    .trim()
    .max(8, "Enter a valid Post code"),
});
const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;
const formurlDefault = "/productLists";
const postalCodesData = {
  data: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: {
      covered_postcodes: [],
    },
  },
};

const postalCodesSearchData = {
  result: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: [[]],
  },
};

function RushMoor() {
  const [postalCodes, setPostalCodes] = useState<any | null>(postalCodesData);
  const [postalCodesSearch, setPostalCodesSearch] = useState<any | null>(
    postalCodesSearchData
  );
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const [formUrl, setFormUrl] = useState<any | null>(formurlDefault);
  const [quickBookPopup, setQuickBookPopup] = useState(false);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [currentCodeValue, setcurrentCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const shopAreaData: any = useSelector<any>((state) => state?.shopsArea);
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  const navigate = useNavigate();

  var metaTitle = "";
  var metaDescription = "";

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });

  const params = useParams<any>();
  useEffect(() => {
    const api_url = process.env.REACT_APP_API_URL;
    const headers = {
      Accept: "application/json",
      
    };

    axios
      .get(
        `${base_url}/api/pincodes_covered_by_district?district=${params.location}`,
        {
          headers: headers,
        }
      )
      .then((e) => {
        setPostalCodes({ data: e.data });
        let areaData = e.data.data.covered_postcodes[0].postcode_area;
        setcurrentCodeValue(areaData);
      });
      metaTitle = "Dry Cleaners in " + params.location?.replace(/-/g, " ") + " | Laundry and Dry Cleaning Services";
      metaDescription = "Discover professional laundry and dry cleaning service providers in " + params.location?.replace(/-/g, " ") + " with LaundryBase. Explore our services and book your appointment online today!";
      document.title = metaTitle;
      document.getElementById("title")?.setAttribute("content", metaTitle);
      document.getElementById("description")?.setAttribute("content", metaDescription);
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  useEffect(() => {
    const area = params.location;
    dispatch(fetchShopsBasedPincode(area, currentCodeValue));
  }, [currentCodeValue]);

  const handleChange = (e: any) => {
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    dispatch(fetchPostalCodes(searchTerm));
  };

  const shopHomePage = (currentShopHome: any, currentShopName: any) => {
    localStorage.setItem("currentShopHome", currentShopHome);
    const shopName = currentShopName;
    const resultOne = shopName.replace(/\s(.)/g, function ($1: string) {
      return $1.toUpperCase();
    });
    const resultTwo = resultOne.replace(/\s/g, "");
    const finalResult = resultTwo.replace(/^(.)/, function ($1: string) {
      return $1.toLowerCase();
    });
    navigate(`/shopHome/${finalResult}`);
  };

  const shopsDataPostcode = (currentCodeValue: any) => {
    const area = params.location;
    dispatch(fetchShopsBasedPincode(area, currentCodeValue));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setValue("postalCode", postalCodeValue.toUpperCase());
    // alert(`${base_url}/api/get_shop_by_pincode/pincode=${postalCodeValue}`)
    const api_url = process.env.REACT_APP_API_URL;
    const headers = {
      Accept: "application/json",
      
    };
    setPostalCodesSearch({ result: null });
    axios
      .get(`${base_url}/api/get_shop_by_pincode?pincode=${postalCodeValue}`, {
        headers: headers,
      })
      .then((e) => {
        setPostalCodesSearch({ result: e.data });

        let lengthvar = e.data.Response.response_message;
        if (
          lengthvar ==
          "Sorry, we didn't cover your postal code yet! Please check for another postal code"
        ) {
          event.preventDefault();
          setFormUrl("/areanotcovered");
          //  alert(JSON.stringify( e.data.data[0].length.toString))
          navigate(`/areanotcovered`);

          //window.open(`${client_base_url}/areanotcovered`);
          return false;
        } else if (lengthvar == "success") {
          setFormUrl("/productLists");

          navigate("/productLists");
        }
      });

    // alert(`Please enter a valid postal code`)
  };

  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);

  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if(getValuesAddressForm("address")?.value != ""){
      clearErrors();
    }
   
  };
  const submitAddressChange =async () => {
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (uniqueAddress.status || uniqueAddress.message == "Address already exists") {
          navigate("/productLists");
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
      
        navigate("/productLists");
      }
    }
  }
  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };

  function openQuickOrderPopup() {
    setQuickBookPopup(!quickBookPopup);
    localStorage.setItem("disableLoader", "yes");
  }
  function closeQuickOrderPopup() {
    setQuickBookPopup(false);
    localStorage.removeItem("disableLoader");
  }

  return (
    <div>
      <div className="area-covered" id="available-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="area-title text-center">
                <h1>
                  <span>Laundry Services &amp; Dry Cleaners in </span>{" "}
                  {params.location?.replace(/-/g, " ")}{" "}
                </h1>
              </div>
              <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                <div className={`area-search-form ${checkWithNewPin == false ? "d-none" : ""}`}>
                  <small className="text-danger postvalidation">
                    {pinCodeFormError.pinCode?.message}
                  </small>
                  
                    <Controller
                      name="pinCode"
                      control={pinCodeFormControl}
                      render={({ field }) => (
                        <input
                          type="text"
                          id="postcode-input"
                          {...field}
                          placeholder="Enter Postcode"
                          className={`form-control area-input-form ${
                            pinCodeFormError.pinCode ? "invalid" : ""
                          }`}
                          // onKeyDown={(e) => {
                          //   if (e.key === ' ') {
                          //     e.preventDefault();
                          //   }
                          // }}
                        />
                      )}
                    />
                    <div className="another-search-area text-center">

                      <button
                        className="submit-area-btn submitCode"
                        id="book_now_btn"
                        type="submit"
                      >
                        Search
                      </button>
                    </div>
                </div>
              </form>
              <form>
                <div
                  className={`area-search-form adrsselectform ${
                    checkWithNewPin == true ? "d-none" : ""
                  }`}
                >
                  <small className="text-danger postvalidation">
                      {addressFormError.address?.message}
                    </small>
                  <div className="adrsselectdd">
                    <Controller
                      name="address"
                      control={addressFormControl}
                      render={({ field }) => (
                        <Select
                          placeholder="Select your address"
                          {...field}
                          value={selectedAddress}
                          className={`${
                            addressFormError.address ? "invalid" : ""
                          }`}
                          onChange={handleAddressChange}
                          onFocus={() => {
                            if (getValuesAddressForm("address").value == "") {
                              addressFormSetError("address", {
                                type: "server",
                                message: "Address is required to proceed",
                              });
                            } else {
                              clearErrors();
                            }
                          }}
                          options={areaOptions}
                        />
                      )}
                    />
                    
                    <input
                        type="button"
                        value="Continue"
                        className="btn hover-btn"
                        onClick={submitAddressChange}
                      />
                  </div>
                  <div className="adrsselect-or">OR</div>
                  <div className="adrsselect-link">
                    <a
                      className="cursor-pointer"
                      onClick={() => {
                        setCheckWithNewPin(true);
                      }}
                    >
                      Check with another Postcode
                    </a>
                  </div>
                </div>
              </form>
              <div className="area-list-01 text-center">
                <h3 className="bold">We Covered the below area postcodes</h3>
                <div className="arealist-slider wow fadeInDown">
                  <div className="arcode-slider">
                    <Swiper
                      spaceBetween={15}
                      navigation={true}
                      modules={[Navigation]}
                    >
                      {postalCodes.data.data.covered_postcodes.map(
                        (covered_postcode: any) => {
                          return (
                            <SwiperSlide>
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  shopsDataPostcode(
                                    covered_postcode.postcode_area
                                  )
                                }
                              >
                                {" "}
                                {covered_postcode.postcode_area}
                              </a>
                            </SwiperSlide>
                          );
                        }
                      )}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-wrapper my-77">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="areacoverd-p">
                <p>
                  We are the Laundry &amp; Dry Cleaners in{" "}
                  {params.location?.replace(/-/g, " ")}. The Laundry Base assures
                  to give the most effective and powerful laundry and delivery
                  services such as wash, tumble dry and fold. You can approach
                  from anywhere by press a single button and easy to download
                  the app.
                </p>
                <p>
                  Laundry Base gets the clean ironing cloths like shirts, pants
                  and blouses. You have option to choose what type of fold which
                  you want. You can order through Laundry Base and give your
                  clothes for pick up then get the fine services in{" "}
                  {params.location?.replace(/-/g, " ")} region.
                </p>
              </div>
              <div className="areacoverd-proc-steps">
                <h2>Procedures to be followed</h2>
                <ul>
                  <li>
                    <span className="prco-img">
                      <img src={procstep1} />
                    </span>
                    <span className="prco-desc">
                      Step 1<h4>Tap</h4>
                    </span>
                  </li>
                  <li>
                    <span className="prco-img">
                      <img src={procstep2} />
                    </span>
                    <span className="prco-desc">
                      Step 2<h4>Wash</h4>
                    </span>
                  </li>
                  <li>
                    <span className="prco-img">
                      <img src={procstep3} />
                    </span>
                    <span className="prco-desc">
                      Step 3<h4>Fold</h4>
                    </span>
                  </li>
                  <li>
                    <span className="prco-img">
                      <img src={procstep4} />
                    </span>
                    <span className="prco-desc">
                      Step 4<h4>Deliver</h4>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="areacoverd-qorder">
                <p>
                  You can just tap in the app for getting the needs of laundry
                  and dry cleaning.
                </p>
                <Link
                        to="#"
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#quickordermodal"
                      >
                        Quick Order
                      </Link>
              </div>
              <div className="areacoverd-footer text-center">
                <h3>
                  Best Dry Cleaning &amp; Laundry service in{" "}
                  <span>{params.location?.replace(/-/g, " ")}</span>
                </h3>
                <p>
                  One of the best dry cleaning apps is Laundry Base that provides
                  the services with collection and delivery for free. We offer
                  many services through online that will be best for tracking.
                  Every order is simple and easy in Laundry Base because you can
                  track any details also award winning app. It is more reliable
                  and trustworthy app.
                </p>
                <div className="row justify-content-center mt-4">
                  <div className="col-md-12">
                    <div className="dry-group-list d-flex">
                      {shopAreaData?.shops?.map((shops: any) => {
                        return (
                          <div className="dry-group">
                            <div className="dry-cleaner-head">
                              {/* <img src={shops?.home_page_setting?.shop_home_page_image?} className="img-m" alt="Shop Logo"/> */}
                              <h4>{shops?.name}</h4>
                            </div>
                            <div className="dry-cleaner-body">
                              <div className="address-dry">
                                <i className="fa-solid fa-location-dot"></i>
                                <p>
                                  <i className="fas fa-map-marked-alt me-1"></i>
                                  {shops?.address?.line_1},{" "}
                                  {shops?.address?.line_2},{" "}
                                  {shops?.address?.line_3}
                                </p>
                                <p className="dry-contact">
                                  <i className="fas fa-phone-alt me-1"></i>
                                  {shops?.address?.mobile_number}
                                </p>
                              </div>

                              <div className="visit-web btn-m">
                                <button
                                  className="btn hover-btn"
                                  // onClick={() =>
                                  //   shopHomePage(shops?.id, shops?.name)
                                  // }
                                  onClick={() =>
                                    navigate("/")
                                  }
                                >
                                  Visit Website
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-md-6">
                                        <div className="dry-group">
                                        
                                        <div className="dry-cleaner-head">
                                        <img src="https://www.bestatlaundry.com/storage/general/img/shops/homepage/logo/shop_9.png" className="img-m" alt="Shop Logo"/>
                                        <h4>Laundry Today </h4>
                                    </div>
                                            <div className="dry-cleaner-body">
                                                <div className="address-dry">
                                                    <i className="fa-solid fa-location-dot"></i>
                                                    <p><i className="fas fa-map-marked-alt me-1"></i>Central London, London,UK</p>
                                                    <p className="dry-contact"><i className="fas fa-phone-alt me-1"></i>08000029446</p>
                                                </div>
                                                
                                                <div className="visit-web btn-m">
                                                    <NavLink to={'./../../shopHome'} className="btn hover-btn">Visit Website</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="quickordermodal"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <QuickOrderPopup close={closeQuickOrderPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RushMoor;
