import React, { useEffect, useState } from "react";
import axios from "axios";
// import AreaCoverHeader from '../../components/areaCoverHeader';
// // import CurrentlyCovered from './../../components/currentlyCovered';
//import CurrentlyCovered from '../../components/currentlyCovered';
import "./../areaCovered/Covered.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;

const areaCoveredData = {
  data: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: {
      areas_covered: {
        R: ["Rushmoor"],
      },
    },
  },
};
const colcount = 0;

function AreaCovered() {
  const navigate = useNavigate();
  const [alphabet, setAlphabet] = useState<Array<string>>([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]);
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const [areaCovered, setAreaCovered] = useState<any | null>(areaCoveredData);

  var metaTitle = "";
  var metaDescription = "";

  useEffect(() => {
    const api_url = process.env.REACT_APP_API_URL;
    const headers = {
      Accept: "application/json",
      
    };

    axios
      .get(`${base_url}/api/areas_covered`, {
        headers: headers,
      })
      .then((e) => {
        setAreaCovered({ data: e.data });
      });

    metaTitle = settingsData?.WebsiteSettings?.site_title_en + " Service Area | Laundry and Dry Cleaning Services in London";
    metaDescription = "Discover the service area covered by "+ settingsData?.WebsiteSettings?.site_title_en +" for laundry and dry cleaning services in London. Check if we service your area and book your services online today!";
    document.title = metaTitle;
    document.getElementById("title")?.setAttribute("content", metaTitle);
    document.getElementById("description")?.setAttribute("content", metaDescription);


  }, []);

  const popAreadCovered = (areaList: any, arealistindex: any) => {
    let keys = Object.keys(areaList);
    let area = keys[0];
    // let city =areaList.area;
    // city=city.toString().toLowerCase();
    //for (let j = arealistindex;j < arealistindex+3 ; j++) {
    var test = Object.keys(areaList).map((location, locationindex) => {
      //alert(areaList[location]);

      let cityArr = areaList[location].toString().split(",");

      return (
        <div className="city-list" id={"group_" + location}>
          <h3>{location}</h3>
          <ul>
            {
              cityArr.map((city: any) => (
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/dry-cleaners/" + city);
                    }}
                  >
                    {city}
                  </a>
                </li>
              )) /*+city.toLowerCase()*/
            }
          </ul>
        </div>
      );
    });

    return test;
  };

  const popAreadCoveredrows = () => {
    let keys = Object.keys(areaCovered.data.data.areas_covered);
    let maxlength = keys.length;
    let rows = Math.ceil(maxlength / 3);

    for (let i = 0; i < rows; i++) {
      let colinit = i * 3;

      if (maxlength != 0) {
        return (
          <div className="alphabet-cont">
            {popAreadCovered(areaCovered.data.data.areas_covered, colinit)}
          </div>
        );
      }
    }
  };

  const checkDisabled = (letter: any) => {
    let keys = Object.keys(areaCovered.data.data.areas_covered);
     return keys.includes(letter)
  };

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Areas we currently cover</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> Area Covered</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-covered">
        <div className="container">
          <div className="row">
            {/* <div className="section-title text-center">
              <h1>Areas we currently covered</h1>
            </div> */}
            <div className="col-md-12">
              <div className="about-us">
                <div className="alphabet-header">
                  {alphabet.map((letters: any) => {
                    return (
                      <>
                        <a
                          style={{
                            cursor: checkDisabled(letters) == false ? "not-allowed" : "",
                          }}
                          className={`${checkDisabled(letters) ? "active":""}`}
                          href={`#group_${letters}`}
                        >
                          {letters}
                        </a>
                      </>
                    );
                  })}
                </div>
                {popAreadCoveredrows()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AreaCovered;
