import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteAddressPopup from "./deleteAddressPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteProfilePopup from "../myProfile/deleteProfilePopup";
import { myprofile2, myorders, myaddress, logout2 } from "../../../assets/img";

function MyAddress() {
  const notify = () => toast("Wow so easy!");

  const navigate = useNavigate();

  const [profile, setProfile] = useState({});
  const [deleteChange, setDeleteChange] = useState(false);
  const [addresses, setaddresses] = useState([]);
  const state: any = useSelector((state) => state);
  const [deleteAddressModal, openDeleteModal] = useState(Boolean);
  const [addressId, setAddressId] = useState(Number);
  const dispatch = useDispatch<any>();

  const rand = Math.random();

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios({
      method: "get",
      url: `${base_url}/api/my_profile`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status != 401) {
          setProfile(response.data.data);
          setaddresses(response.data.data.addresses);
        }
      })
      .catch(function (error) {});
  }, [state]);

  function openModal(id: number) {
    setAddressId(id);
    openDeleteModal(true);
  }

  function navigateToEdit(item: any) {
    navigate(`/editAddress/${item.address_id}`);
  }

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const profileData: { [unit: string]: number } = profile;

  return (
    <div>
      <section className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Manage Address</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> Manage Address</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-myprofile">
        <div className="container">
          <div className="row">
            <div className="col-md-12 custom-myprofile">
              <h1>Manage Address</h1>
              <span className="float-end">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                >
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  Back to home
                </a>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="user-profile">
                <div className="user-profile-details">
                  <span className="db-img">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/editProfile");
                      }}
                    >
                      <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                  </span>
                  <img
                    className="user-profile-img"
                    src={`${profileData.web_photo}?bust=${rand}`}
                  />
                  <h4>
                    <small>Hello,</small> {profileData.name}
                  </h4>
                </div>
                <div className="orders-account">
                  <div
                    className="myorders"
                    onClick={() => navigate("/myProfile")}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/myProfile");
                      }}
                    >
                      <i className="fas fa-layer-group"></i>
                      <h4>My Profile</h4>
                    </a>
                  </div>
                  <div
                    className="myorders"
                    onClick={() => navigate("/myOrders")}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/myOrders");
                      }}
                    >
                      <i className="fas fa-tag"></i>
                      <h4>My Orders</h4>
                    </a>
                  </div>
                  <div
                    className="myorders"
                    onClick={() => navigate("/myAddress")}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/myAddress");
                      }}
                    >
                      <i className="fas fa-map-marker-alt"></i>
                      <h4>My Address</h4>
                    </a>
                  </div>
                  {/* <div className="myorders">
                                        <button className="btn btn-danger remove_option m-sm-1" type="button" data-bs-toggle="modal" data-bs-target="#deleteprofile" data-id="1" onClick={() => setDeleteChange(!deleteChange)}><i className="fa fa-trash"></i></button>
                                        <h4>Delete Profile</h4>
                                    </div> */}
                  <div className="myorders">
                    <a
                      href="#"
                      onClick={(e) => {
                        handleLogout(e);
                      }}
                    >
                      <i className="fas fa-power-off"></i>
                      <h4>Logout</h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-details-right">
                <div className="row manageaddress">
                  <div className="col-md-6">
                    <h5>Manage Address</h5>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/newAddress");
                      }}
                      className="float-end"
                    >
                      Add new address
                    </a>
                  </div>
                </div>
                <div className="row row-eq-height">
                  {addresses.map((item: any) => {
                    return (
                      <div className="col-lg-4">
                        <div className="filled-address-custom">
                          <div className="custom-address">
                            <div className="custom-address-title">
                              <h4>{item.label}</h4>
                              <div className="custom-edit-btn float-end">
                                <span>
                                  <a
                                    onClick={() => {
                                      navigateToEdit(item);
                                    }}
                                  >
                                    <i
                                      className="fas fa-pencil-alt address-edit"
                                      aria-hidden="true"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </a>
                                </span>
                                <span>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_address_popup"
                                    onClick={() => openModal(item.address_id)}
                                  >
                                    <i
                                      className="fa fa-trash address-delete"
                                      aria-hidden="true"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                            <div className="custom-address-content">
                              <div className="svd-address">
                                <span style={{ fontWeight: "bold" }}>
                                  {item.contact_name}
                                </span>
                                <br />
                                {item.line_1 != "" ? `${item.line_1}, ` : ""}
                                {item.line_2 != "" ? `${item.line_2}, ` : ""}
                                {item.line_3 != "" ? `${item.line_3}, ` : ""}
                                {item.line_4 != "" ? `${item.line_4}, ` : ""}
                                <br />
                                {item.city != "" ? `${item.city},` : ""}
                                <br />
                                {item.county != "" ? `${item.county},` : ""}
                                <br />
                                {item.postcode != "" ? `${item.postcode},` : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {addresses.length === 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="res-not-found res-not-found-adrs  text-center">
                        <div className="res-not-icon">
                          <i className="fas fa-search"></i>
                        </div>
                        <h1>No Address Found</h1>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteAddressModal && (
        <DeleteAddressPopup
          close={() => openDeleteModal(false)}
          id={addressId}
        />
      )}
      {deleteChange && (
        <DeleteProfilePopup close={() => setDeleteChange(false)} />
      )}
      <ToastContainer />
    </div>
  );
}

export default MyAddress;
