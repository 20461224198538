import React, {useEffect, useState} from 'react'
import axios from 'axios'
import BookNow from '../../components/bookNow';
import WeWork from '../../components/weWork';
import PricingList from '../../components/pricingList';
import OrderOnline from '../../components/orderOnline';
import AboutBestAtLaundry from '../../components/aboutBestAtLaundry';
import LaundryService from '../../components/laundryService';
import BestOfferImage from '../../components/bestOfferImage';
import Offer from '../../components/offer';
import DownloadApp from '../../components/downloadApp'; 
import Howitworks from '../../components/howitWorks';
import AreaNotCovered from '../../components/areaNotCovered';
 import Notification from '../../components/notification';
 import Ourservices from '../../components/ourServices'
import GoogleReviews from '../../components/googleReview';
import { useSelector } from 'react-redux';


function Home() {

  const settingsData: any = useSelector<any>((state) => state?.settings);
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const [getOffer, setGetOffer] = useState<any>();
  const [offerMessage, setOfferMessage] = useState<any>();

  var metaTitle = "";
  var metaDescription = "";

  useEffect(() => {     
    const api_url = process.env.REACT_APP_API_URL;  
    const headers = {
        'Accept': 'application/json',
        
      }
      
          axios.get(`${base_url}/api/list_offer_details`,{
          headers: headers

}).then(e=>{setGetOffer({result:e.data.data.offer_details})
  setOfferMessage(e.data.Response.response_message)
}
)

  metaTitle = settingsData?.WebsiteSettings?.site_title_en + " | Convenient Laundry and Dry Cleaning Services";
  metaDescription = "Discover " + settingsData?.WebsiteSettings?.site_title_en + " for convenient laundry and dry cleaning services. Book your services online and experience hassle-free cleaning today!";
  document.title = metaTitle;
  document.getElementById("title")?.setAttribute("content", metaTitle);
  document.getElementById("description")?.setAttribute("content", metaDescription);

}, [])

  return (
    <div>
      
      <BookNow />
      <WeWork />
      <Ourservices/>         
      {/* <BestOfferImage /> */}
      {/* <PricingList /> */}
      {getOffer?.result?.length > 0 &&
      offerMessage !== "no offers available right now" &&
      <Offer />
      }   
      <OrderOnline />
      <AboutBestAtLaundry />
      <LaundryService />
      {/* <GoogleReviews /> */}
      <DownloadApp />
      {/* <AreaNotCovered /> */}
      
    </div>
  )
}

export default Home